import React, { Component } from 'react'
import Menu from '../component/menu' 
import Service from '../component/service' 
import Footer from '../component/footer' 


export default class PageService extends Component {    
    render() {
        return (
            <div>
                <Menu App={this.props.App}/>
                <Service/>
                <Footer/>
            </div>
        )
    }
}