import React, { Component } from 'react';
import Notfound from '../component/notfound';  
export default class PageNotfound extends Component {     
  render() {
    return (
      <div>
        <Notfound/>
      </div>
    );
  }
}
