import React, { Component } from 'react'
import Menu from '../component/menu'
import Home from '../component/home'
import Footer from '../component/footer'
export default class PageHome extends Component {
  render() {
    return (
      <div>
        <Menu App={this.props.App} />
        <Home App={this.props.App} />
        <Footer />
      </div>
    );
  }
}
