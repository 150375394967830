import React, { Component } from 'react'
import Menu from '../component/menu'
import About from '../component/about'
import Footer from '../component/footer'


export default class PageAbout extends Component {
    render() {
        return (
            <div>
                <Menu App={this.props.App} />
                <About App={this.props.App} />
                <Footer />
            </div>
        )
    }
}