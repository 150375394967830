import React, { Component } from 'react'
import Menu from '../component/menu'
import News from '../component/news'
import NewsMore from '../component/news/news-more'
import Footer from '../component/footer'
import _ from 'lodash'
import Service from '../service'
import Loading from '../component/loading'
import queryString from 'query-string'

export default class PageNews extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            items: [],
            itemsMore: [],
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        Service.getNews()
            .then(response => {
                this.setState({
                    loading: false,
                    items: _.isEmpty(queryString.parse(this.props.history.location.search)) && response.data.data,
                    itemsMore: _.isEmpty(!queryString.parse(this.props.history.location.search)) && response.data.data[(queryString.parse(this.props.history.location.search)).id - 1]

                })
            })
            .catch(error => {
                console.log(error)
            })
    }

    render() {
        return (
            <div>
                <Loading loading={this.state.loading} />
                <Menu App={this.props.App} />
                {this.state.items
                    ? <News items={this.state.items} />
                    : this.state.itemsMore ? <NewsMore items={this.state.itemsMore} /> : "123123123123213213"
                }
                <Footer />
            </div>
        )
    }
}