import React, { Component } from 'react'
import Swiper from 'react-id-swiper'
import 'react-id-swiper/src/styles/css/swiper.css'
import { Container, Row, Col, } from 'reactstrap'
import Loading from '../loading'
import Service from '../../service'
import _ from 'lodash'
import { translate } from 'react-i18next'

const params1 = {
    slidesPerView: 3,
    spaceBetween: 10,
    shouldSwiperUpdate: true,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    breakpoints: {
        991.98: { slidesPerView: 2 },
        575.98: { slidesPerView: 1 }
    }
}

const params2 = {
    slidesPerView: 3,
    spaceBetween: -45,
    shouldSwiperUpdate: true,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    breakpoints: {
        1199.98: { slidesPerView: 2 },
        991.98: { slidesPerView: 1 }
    }
}

const params3 = {
    slidesPerView: 1,
    autoplay: {
        delay: 5000,
        disableOnInteraction: false
    },
    shouldSwiperUpdate: true,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    pagination: {
        el: '.swiper-pagination',
        clickable: true
    }
}

class About extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            items: [],
            index: 0,
            itemsShow: []
        }
    }

    componentDidMount() {
        Service.getAbout()
            .then(response => {
                this.setState({ loading: false, items: response.data.data })
            })
            .catch(error => {
                console.error(error)
            })
    }

    breakLine = (text) => {
        var br = React.createElement('br');
        var regex = /(<br\/>)/g;
        return text.split(regex).map(function (line, index) {
            return line.match(regex) ? <br key={"key_" + index} /> : line;
        })
    }

    render() {
        const { t } = this.props
        return (
            <div>
                <Loading loading={this.state.loading} />
                <section className="p-t-56">
                    <div className="bg-image-about screen50 d-flex align-items-center">
                        <Container>
                            <Row className="align-items-center flex-wrap-reverse text-green">
                                <Col md="6">
                                    <h2 className="font-weight-bold text-white">A&A CONTROL</h2>
                                    <p className="py-4 text-white">{t('home-1')}</p>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </section>

                <section>
                    <div className="bg-blue">
                        <Container className="pt-4 pb-2">
                            <Swiper {...params1}>
                                <div className="grid">
                                    <figure className="effect-sadie">
                                        <img className="img-fluid" src="./asset/images/staff/Managing Director.png" alt="Managing Director" />
                                        <figcaption>
                                            <h5><span className="font-weight-bold">{t('about-1')}</span></h5>
                                            <p>{t('about-4')}</p>
                                        </figcaption>
                                    </figure>
                                </div>
                                <div className="grid">
                                    <figure className="effect-sadie">
                                        <img className="img-fluid" src="./asset/images/staff/Deputy Managing Director and team.png" alt="Deputy Managing Director and team" />
                                        <figcaption>
                                            <h5><span className="font-weight-bold">{t('about-2')}</span></h5>
                                            <p>{t('about-4')}</p>
                                        </figcaption>
                                    </figure>
                                </div>
                                <div className="grid">
                                    <figure className="effect-sadie">
                                        <img className="img-fluid" src="./asset/images/staff/our_team-1.png" alt="our_team-1" />
                                        <figcaption>
                                            <h5><span className="font-weight-bold">{t('about-3')}</span></h5>
                                            <p>{t('about-4')}</p>
                                        </figcaption>
                                    </figure>
                                </div>
                                <div className="grid">
                                    <figure className="effect-sadie">
                                        <img className="img-fluid" src="./asset/images/staff/our_team-2.png" alt="our_team-2" />
                                        <figcaption>
                                            <h5><span className="font-weight-bold">{t('about-3')}</span></h5>
                                            <p>{t('about-4')}</p>
                                        </figcaption>
                                    </figure>
                                </div>
                            </Swiper>
                        </Container>
                    </div>
                </section>

                <section>
                    <Container className="py-5">
                        <Swiper {...params2}>
                            {this.state.items.map((items, index) => {
                                return (
                                    <div key={`items-${index}`} onClick={() => this.setState({ index: index, itemsShow: items })} className="px-5">
                                        <div className={"hov-bg1 " + (this.state.index === index ? "border-green bg-green-dark text-white" : "border-green bg-white text-green")}>
                                            <p className="c-pointer slide-title">{items.title}</p>
                                        </div>
                                    </div>
                                )
                            })}
                        </Swiper>
                    </Container>
                </section>

                <section className="p-b-100">
                    <Container className="text-center">
                        <h3 className="text-stroke text-left pb-5">
                            {_.isEmpty(this.state.items) ? "" : _.isEmpty(this.state.itemsShow) ? this.state.items[0].title : this.state.itemsShow.title}
                        </h3>
                        <Swiper {...params3}>
                            {_.isEmpty(this.state.items) ? "" : _.isEmpty(this.state.itemsShow) ?
                                this.state.items[0].images.map((items, index) => {
                                    return (
                                        <div key={`items-${index}`} className="pb-5">
                                            <Col xs="12">
                                                <img className="img-fluid rounded shadow-lg" src={items} alt={items} />
                                            </Col>
                                        </div>
                                    )
                                })
                                :
                                this.state.itemsShow.images.map((items, index) => {
                                    return (
                                        <div key={`items-${index}`} className="pb-5">
                                            <Col xs="12">
                                                <img className="img-fluid rounded shadow-lg" src={items} alt={items} />
                                            </Col>
                                        </div>
                                    )
                                })
                            }
                        </Swiper>
                        <p className="p-t-25 p-b-25 text-justify">
                            {_.isEmpty(this.state.items)
                                ? ""
                                : _.isEmpty(this.state.itemsShow)
                                    ? this.props.App.Language === "th" ? this.breakLine(this.state.items[0].detailth) : this.breakLine(this.state.items[0].detailen)
                                    : this.props.App.Language === "th" ? this.breakLine(this.state.itemsShow.detailth) : this.breakLine(this.state.itemsShow.detailen)
                            }
                        </p>
                    </Container>
                </section>
            </div>
        )
    }
}

export default translate()(About)