import React, { Component } from 'react'
import ReCAPTCHA from "react-google-recaptcha"
import { Container, Row, Col, Button, Form, FormGroup, Input, FormFeedback } from 'reactstrap'
import { Link } from 'react-router-dom'
import GoogleMapReact from 'google-map-react'
import { translate } from 'react-i18next'
import Service from '../../service'
import Loading from '../loading'

const recaptchaRef = React.createRef()

class Footer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            name: null,
            email: null,
            subject: null,
            message: null,
            recaptcha: null,
            alertColor: "text-danger",
            alertMessage: ""
        }
    }

    renderMarkers(map, maps) {
        new maps.Marker({
            position: { lat: 13.6662181, lng: 100.6155297 },
            map,
            title: 'A&A CONTROL CO., LTD'
        });
    }

    Sendmessage = (e) => {
        e.preventDefault()
        if (this.state.recaptcha) {
            this.setState({ loading: true })
            const data = {
                'name': this.state.name,
                'email': this.state.email,
                'subject': this.state.subject,
                'message': this.state.message,
                'recaptcha': this.state.recaptcha
            }
            Service.SendMail(data)
                .then(response => {
                    this.setState({
                        loading: false,
                        alertColor: response.data.status.code === "200" && "text-success",
                        alertMessage: response.data.status.message
                    })
                })
                .catch(error => {
                    console.log(error)
                })
        } else {
            this.setState({ alertMessage: "กรุณาคลิก เพื่อยืนยันตัวตน" })
        }
    }

    render() {
        const { t } = this.props
        return (
            <div>
                <Loading loading={this.state.loading} />
                <section className="py-4 bg-image2">
                    <Container>
                        <Row className="justify-content-between align-items-center text-white">
                            <Col md="10" className="py-4">
                                <h4 className="font-weight-bold w-100">If a problem arises</h4>
                                <h5 className="font-weight-bold w-100">They can work on-site</h5>
                                <h5 className="font-weight-bold w-100">in collaboration with customer's maintenance staff.</h5>
                            </Col>
                            <Col md="2" className="text-center">
                                <Link to="/contact"><Button color="white" className="px-4 py-3">{t('footer-2')} <i className="fas fa-angle-right" /></Button></Link>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <footer className="bg-yellow1 p-t-100 p-b-100">
                    <Container className="py-5">
                        <Row className="flex-wrap-reverse">
                            <Col lg="7">
                                <Form onSubmit={this.Sendmessage}>
                                    <FormGroup>
                                        <input type="text" className="form-control" name="name" placeholder={t('contact-4')} onChange={(e) => this.setState({ name: e.target.value })} required />
                                    </FormGroup>
                                    <FormGroup>
                                        <input type="email" className="form-control" name="email" placeholder={t('contact-5')} onChange={(e) => this.setState({ email: e.target.value })} required />
                                    </FormGroup>
                                    <FormGroup>
                                        <input type="text" className="form-control" name="subject" placeholder={t('contact-6')} onChange={(e) => this.setState({ subject: e.target.value })} required />
                                        <FormFeedback>* please enter your name</FormFeedback>
                                    </FormGroup>
                                    <FormGroup>
                                        <Input type="textarea" className="form-control" name="message" rows="6" placeholder={t('contact-7')} onChange={(e) => this.setState({ message: e.target.value })} required />
                                        <FormFeedback>* please enter your name</FormFeedback>
                                    </FormGroup>
                                    <FormGroup>
                                        <div className={this.state.alertColor}>{this.state.alertMessage}</div>
                                    </FormGroup>
                                    <FormGroup>
                                        <ReCAPTCHA ref={recaptchaRef} sitekey="6LcmHnoUAAAAADQykIbH-RqQ3t4CdkYVr-V9pYQ8" name="recaptcha" onChange={(value) => this.setState({ recaptcha: value })} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Button color="info" className="px-4 py-3 btn-blue" type="submit">{t('footer-3')}</Button>
                                    </FormGroup>
                                </Form>
                            </Col>
                            <Col lg="5" className="d-flex flex-column pb-4">
                                <h5 className="text-green font-weight-bold">{t('footer-4')}</h5>
                                <div className="d-flex align-items-start pt-2 pb-4">
                                    <p>{t('footer-5')}</p>
                                </div>
                                <div className="d-flex align-items-start py-2">
                                    <i className="fas fa-envelope fa-2x text-green"></i>
                                    <div className="px-4">
                                        <a href="mailto:contact@aandacontrol.com">contact@aandacontrol.com</a><br />
                                        <a href="mailto:contact@aacontrol.co.th">contact@aacontrol.co.th</a>
                                    </div>
                                </div>
                                <div className="d-flex align-items-start py-2">
                                    <i className="fas fa-phone fa-2x text-green my-auto"></i>
                                    <div className="px-4">
                                        <a href="tel:0-2744-4961">Tel : 0-2744-4961</a><br />
                                        <a href="tel:0-2398-9538">Tel : 0-2398-9538-9</a>
                                    </div>
                                </div>
                                <div className="d-flex align-items-start py-2">
                                    <i className="fas fa-fax fa-2x text-green my-auto"></i>
                                    <div className="px-4">
                                        <p>Fax : 0-2744-4904</p>
                                        <p>Fax : 0-2398-6650</p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-start py-2">
                                    <i className="fab fa-facebook fa-2x text-facebook my-auto"></i>
                                    <div className="px-4">
                                        <p>
                                            <a href="https://www.facebook.com/AAControlcoLtd" target="_blank" rel="noopener noreferrer">www.facebook.com/AAControlcoLtd</a>
                                        </p>
                                    </div>
                                </div>
                                <div className="py-2">
                                    <i className="fab fa-line fa-2x text-line"></i>
                                    <span className="px-4">
                                        <img src="/asset/images/line.jpg" width="100px" alt="line@" />
                                    </span>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </footer>

                <footer>
                    <h5 className="text-center bg-yellow1 pb-3"><a href="https://goo.gl/maps/wGJBc6MQvjt" target="_blank" rel="noopener noreferrer">{t('footer-6')}</a></h5>
                    <div style={{ height: '40vh', width: '100%' }}>
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: "AIzaSyCheRot8a2v6ihzAPqlpIFZNe4atTnX444" }}
                            defaultCenter={{ lat: 13.6662181, lng: 100.6155297 }}
                            defaultZoom={16}
                            onGoogleApiLoaded={({ map, maps }) => this.renderMarkers(map, maps)}
                            yesIWantToUseGoogleMapApiInternals={true} >
                        </GoogleMapReact>
                    </div>
                    <div className="bg-green-dark">
                        <Container className="py-2 text-center text-white">
                            <small>Copyright © 2019 A&A CONTROL CO., LTD All right reserved.</small>
                        </Container>
                    </div>
                </footer>
            </div >
        );
    }
}

export default translate()(Footer)