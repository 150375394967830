import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'animate.css/animate.min.css'
import './style/hover.css' 
import './style/util.scss'
import './style/main.scss'
import PageHome from './container/home'
import PageAbout from './container/about'
import PageProduct from './container/product'
import PageService from './container/service'
import PageReference from './container/reference'
import PageNews from './container/news'
import PageContact from './container/contact'
import PageNotfound from './container/notfound'
import { Switch, Route } from 'react-router-dom'


export default class App extends Component {

  constructor(props) {
    super(props)
    this.Language = "th"
  }

  componentDidUpdate() { window.scrollTo(0, 0) }

  render() {
    return (
      <div>
        <Switch>
          <Route exact path="/" component={(history) => <PageHome App={this} history={history} />} />
          <Route exact path="/about" component={(history) => <PageAbout App={this} history={history} />} />
          <Route exact path="/product" component={(history) => <PageProduct App={this} history={history} />} />
          <Route exact path="/service" component={(history) => <PageService App={this} history={history} />} />
          <Route exact path="/reference" component={(history) => <PageReference App={this} history={history} />} />
          <Route exact path="/news" component={(history) => <PageNews App={this} history={history} />} />
          <Route exact path="/contact" component={(history) => <PageContact App={this} history={history} />} />          

          <Route exact path="/index.html" component={(history) => <PageHome App={this} history={history} />} />
          <Route exact path="/index_th.html" component={(history) => <PageHome App={this} history={history} />} />
          <Route exact path="/company_profile_th.html" component={(history) => <PageAbout App={this} history={history} />} />
          <Route exact path="/company_profile_eng.html" component={(history) => <PageAbout App={this} history={history} />} />
          <Route exact path="/product_eng.html" component={(history) => <PageProduct App={this} history={history} />} />
          <Route exact path="/product_dis_eng.html" component={(history) => <PageProduct App={this} history={history} />} />
          <Route exact path="/site_ref_eng.html" component={(history) => <PageService App={this} history={history} />} />
          <Route exact path="/field_th.html" component={(history) => <PageReference App={this} history={history} />} />
          <Route exact path="/field_eng.html" component={(history) => <PageReference App={this} history={history} />} />
          <Route exact path="/contact_th.html" component={(history) => <PageContact App={this} history={history} />} />
          <Route exact path="/contact_eng.html" component={(history) => <PageContact App={this} history={history} />} />  

          <Route component={PageNotfound} />        
        </Switch>
      </div>
    );
  }
}