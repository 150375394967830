import React, { Component } from 'react'
import Menu from '../component/menu'
import Product from '../component/product'
import Footer from '../component/footer'
import _ from 'lodash'


export default class PageProduct extends Component {
    constructor(props) {
        super(props)
        this.state = { index: _.isEmpty(this.props.history.location.state) ? 0 : this.props.history.location.state.index }
    }

    render() {
        return (
            <div>
                <Menu App={this.props.App} />
                <Product index={this.state.index} />
                <Footer />
            </div>
        )
    }
}