import React, { Component } from 'react'
import { Container } from 'reactstrap'
import Swiper from 'react-id-swiper'
import { Link } from 'react-router-dom'
import 'react-id-swiper/src/styles/css/swiper.css'
import { FacebookIcon, TwitterIcon, GooglePlusIcon, LinkedinIcon, FacebookShareButton, GooglePlusShareButton, LinkedinShareButton, TwitterShareButton, } from 'react-share'
import { translate } from 'react-i18next'

const params = {
    slidesPerView: 1,
    autoplay: {
        delay: 5000,
        disableOnInteraction: false
    },
    rebuildOnUpdate: true,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    pagination: {
        el: '.swiper-pagination',
        clickable: true
    }
}

class NewsMore extends Component {
    constructor(props) {
        super(props)
        this.state = { items: this.props.items }
    }

    componentDidMount() {
        this.setState({ items: this.props.items })
    }

    render() {
        const {t} = this.props
        return (
            <div>
                <section className="p-t-56 p-b-100">
                    <Container>
                        <h3 className="pt-5 pb-2 mt-4 text-green font-weight-bold">{this.props.items.title}</h3>
                        <p className="text-muted">
                            <small>
                                <Link to={{ pathname: "/news", search: "" }}>{t('menu-6')}</Link> / <strong>{this.props.items.title}</strong>
                            </small>
                        </p>
                        <p><small>{t('new-2')} {this.props.items.username}</small></p>
                        <p><small>{t('new-3')} {this.props.items.day}</small></p>
                        <Swiper {...params}>
                            {this.props.items.more.map((items, index) => {
                                return (
                                    <div className="text-center" key={`items-${index}`}>
                                        <img className="px-0 px-md-4 py-5 img-fluid" src={items} alt="project" />
                                    </div>
                                )
                            })}
                        </Swiper>
                        <p className="pt-2 pb-5">{this.props.items.detail}</p>
                    </Container>
                </section>

                <section className="bg-blue">
                    <Container className="d-flex align-items-center justify-content-between py-4">
                        <h6><strong>{t('reference-5')}</strong></h6>
                        <div className="d-flex text-white">
                            <div className="mx-2 hov3">
                                <FacebookShareButton url={"https://www.aandacontrol.com/news?id=" + this.props.items.id}>
                                    <FacebookIcon size={35} round={true} />
                                </FacebookShareButton>
                            </div>
                            <div className="mx-2 hov3">
                                <TwitterShareButton url={"https://www.aandacontrol.com/news?id=" + this.props.items.id}>
                                    <TwitterIcon size={35} round={true} />
                                </TwitterShareButton>
                            </div>
                            <div className="mx-2 hov3">
                                <GooglePlusShareButton url={"https://www.aandacontrol.com/news?id=" + this.props.items.id}>
                                    <GooglePlusIcon size={35} round={true} />
                                </GooglePlusShareButton>
                            </div>
                            <div className="mx-2 hov3">
                                <LinkedinShareButton url={"https://www.aandacontrol.com/news?id=" + this.props.items.id}>
                                    <LinkedinIcon size={35} round={true} />
                                </LinkedinShareButton>
                            </div>
                        </div>
                    </Container>
                </section>
            </div>
        )
    }
}
export default translate()(NewsMore)