import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class Notfound extends Component {
    render() {
        return (
            <div className="bg-g-tb height-fullscreen">
                <div className="ab-c-m text-center">
                    <i className="far fa-frown fa-5x" />
                    <h1 className="py-4">404</h1>
                    <h5 className="pb-4 text-muted">Page not found</h5>
                    <p>This Page you are looking fot doesn't exist or an other error occurred.</p>
                    <p><Link to="/" className="text-green"><u>Go back</u></Link>, or head over to <Link to="/" className="text-green"><u>aandacontrol.com</u></Link> to choose a new direction.</p>
                </div>
            </div>
        );
    }
}
