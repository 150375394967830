import React, { Component } from 'react'
import './style.scss'
import {
  Container,
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem
} from 'reactstrap'
import { NavLink } from 'react-router-dom'
import { translate } from 'react-i18next'
import i18n from './../../../src/i18n'

class Menu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      isLanguage: this.props.App.Language === "th"
    }
  }



  toggle = () => { this.setState({ isOpen: !this.state.isOpen }) }

  language = () => {
    this.props.App.Language = !this.state.isLanguage ? 'th' : 'en'
    i18n.changeLanguage(this.props.App.Language)
    this.setState({ isLanguage: !this.state.isLanguage })
  }

  render() {
    const { t } = this.props
    return (
      <div className="top-menu">
        <Navbar fixed="top" color="light" light expand="lg" className="shadow-lg">
          <Container>
            <NavLink to="/"><img className="img-fluid" src="./asset/images/logo.png" alt="" width="80px" /></NavLink>
            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="ml-auto" navbar>
                <NavItem>
                  <NavLink exact to="/" className="nav-link" activeClassName="menu-active">{t('menu-1')}</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink exact to="/about" className="nav-link" activeClassName="menu-active">{t('menu-2')}</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink exact to="/product" className="nav-link" activeClassName="menu-active">{t('menu-3')}</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink exact to="/service" className="nav-link" activeClassName="menu-active">{t('menu-4')}</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink exact to="/reference" className="nav-link" activeClassName="menu-active">{t('menu-5')}</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink exact to="/news" className="nav-link" activeClassName="menu-active">{t('menu-6')}</NavLink>
                </NavItem>
                <NavItem className="mr-5">
                  <NavLink exact to="/contact" className="nav-link" activeClassName="menu-active">{t('menu-7')}</NavLink>
                </NavItem>
                <NavItem>
                  <div className="onoffswitch mt-1">
                    <input type="checkbox" name="onoffswitch" className="onoffswitch-checkbox" id="myonoffswitch" defaultChecked={this.state.isLanguage} onClick={this.language} />
                    <label className="onoffswitch-label" htmlFor="myonoffswitch">
                      <span className="onoffswitch-inner"></span>
                      <span className="onoffswitch-switch"></span>
                    </label>
                  </div>
                </NavItem>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </div>
    )
  }
}

export default translate()(Menu)
