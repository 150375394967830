import axios from 'axios'

const Service = {
  getAbout() {
    return axios.get('./asset/api/about.json')
  },
  getProduct() {
    return axios.get('./asset/api/product.json')
  },
  getReference() {
    return axios.get('./asset/api/reference.json')
  },
  getNews() {
    return axios.get('./asset/api/news.json')
  },
  SendMail(e) {
    return axios.post('https://www.aandacontrol.com/Sendmail/index.php', e,{ headers: { 'Content-Type': 'application/json' } })
  },
}

export default Service