import React, { Component } from 'react'
import './style.scss'
import { Container, Col } from 'reactstrap'
import Swiper from 'react-id-swiper'
import 'react-id-swiper/src/styles/css/swiper.css'
import { translate } from 'react-i18next'

const params = {
    slidesPerView: 1,
    autoplay: {
        delay: 5000,
        disableOnInteraction: false
    },
    rebuildOnUpdate: true,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    pagination: {
        el: '.swiper-pagination',
        clickable: true
    }
}
class Service extends Component {
    render() {
        const { t } = this.props
        return (
            <div>
                <section className="p-t-100">
                    <Container className="pt-5">
                        <Col className="d-flex flex-wrap justify-content-between align-items-center pb-5">
                            <img className="img-fluid" src="./asset/images/service/HL-field_SERVICE.png" width="400px" alt="HL-field_SERVICE" />
                        </Col>
                    </Container>
                </section>

                <section>
                    <Container className="pb-4">
                        <Swiper {...params}>
                            <div className="px-0 px-md-5 pb-5">
                                <img className="img-fluid" src="./asset/images/service/field-service-01.png" alt="field-service-01" />
                            </div>
                            <div className="px-0 px-md-5 pb-5">
                                <img className="img-fluid" src="./asset/images/service/field-service-02.png" alt="field-service-02" />
                            </div>
                            <div className="px-0 px-md-5 pb-5">
                                <img className="img-fluid" src="./asset/images/service/field-service-03.png" alt="field-service-03" />
                            </div>
                            <div className="px-0 px-md-5 pb-5">
                                <img className="img-fluid" src="./asset/images/service/field-service-04.png" alt="field-service-04" />
                            </div>
                            <div className="px-0 px-md-5 pb-5">
                                <img className="img-fluid" src="./asset/images/service/field-service-05.png" alt="field-service-05" />
                            </div>
                            <div className="px-0 px-md-5 pb-5">
                                <img className="img-fluid" src="./asset/images/service/field-service-06.png" alt="field-service-06" />
                            </div>
                            <div className="px-0 px-md-5 pb-5">
                                <img className="img-fluid" src="./asset/images/service/field-service-07.png" alt="field-service-07" />
                            </div>
                            <div className="px-0 px-sm-5 pb-5">
                                <img className="img-fluid" src="./asset/images/service/field-service-08.png" alt="field-service-08" />
                            </div>
                            <div className="px-0 px-sm-5 pb-5">
                                <img className="img-fluid" src="./asset/images/service/field-service-09.png" alt="field-service-09" />
                            </div>
                            <div className="px-0 px-sm-5 pb-5">
                                <img className="img-fluid" src="./asset/images/service/field-service-10.png" alt="field-service-10" />
                            </div>
                            <div className="px-0 px-sm-5 pb-5">
                                <img className="img-fluid" src="./asset/images/service/field-service-11.png" alt="field-service-11" />
                            </div>
                            <div className="px-0 px-sm-5 pb-5">
                                <img className="img-fluid" src="./asset/images/service/field-service-12.png" alt="field-service-12" />
                            </div>
                            <div className="px-0 px-sm-5 pb-5">
                                <img className="img-fluid" src="./asset/images/service/field-service-13.png" alt="field-service-13" />
                            </div>
                            <div className="px-0 px-sm-5 pb-5">
                                <img className="img-fluid" src="./asset/images/service/field-service-14.png" alt="field-service-14" />
                            </div>
                        </Swiper>
                    </Container>
                </section>

                <section className="p-b-100">
                    <Container>
                        <Col>
                            <h5 className="text-stroke pb-3">{t('service-1')}</h5>
                            <ol>
                                <li>{t('service-2')}</li>
                                <li>{t('service-3')}</li>
                                <li>{t('service-4')}</li>
                                <li>{t('service-5')}
                                <ul>
                                        <li>{t('service-6')}</li>
                                        <li>{t('service-7')}</li>
                                        <li>{t('service-8')}</li>
                                        <li>{t('service-9')}</li>
                                        <li>{t('service-10')}</li>
                                        <li>{t('service-11')}</li>
                                        <li>{t('service-12')}</li>
                                        <li>{t('service-13')}</li>
                                    </ul>
                                </li>
                                <li>{t('service-14')}</li>
                                <li>{t('service-15')}</li>
                                <li>{t('service-16')}</li>
                                <li>{t('service-17')}</li>
                                <li>{t('service-18')}</li>
                                <li>{t('service-19')}</li>
                            </ol>
                        </Col>
                    </Container>
                </section>
            </div>
        )
    }
}
export default translate()(Service)