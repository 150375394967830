import React, { Component } from 'react'
import { Container, Row, Col } from 'reactstrap'
import Swiper from 'react-id-swiper'
import 'react-id-swiper/src/styles/css/swiper.css'
import { translate } from 'react-i18next'

const params1 = {
    slidesPerView: 3,
    spaceBetween: -40,
    shouldSwiperUpdate: true,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    breakpoints: {
        991.98: { slidesPerView: 2 },
        575.98: { slidesPerView: 1 }
    }
}

const params2 = {
    slidesPerView: 1,
    spaceBetween: 10,
    autoplay: {
        delay: 5000,
        disableOnInteraction: false
    },
    rebuildOnUpdate: true,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    pagination: {
        el: '.swiper-pagination',
        clickable: true
    }
}
class Product extends Component {
    constructor(props) {
        super(props)
        this.state = { index: this.props.index }
    }

    breakLine = (text) => {
        var br = React.createElement('br');
        var regex = /(<br\/>)/g;
        return text.split(regex).map(function (line, index) {
            return line.match(regex) ? <br key={"key_" + index} /> : line;
        })
    }

    render() {
        const { t } = this.props
        return (
            <div>
                <section className="p-t-56">
                    <Container className="py-5">
                        <p style={{ textIndent: "2em" }}>{t('product-1')}</p>
                        <div className="d-flex justify-content-center">
                            <Col sm="9" md="8" lg="7"><h3 className="text-center text-green py-5 font-weight-bold">“{t('home-3')}”</h3></Col>
                        </div>
                        <p>{t('product-2')}</p>
                    </Container>
                </section>

                <section>
                    <div className="bg-green-dark">
                        <Container className="py-4">
                            <Row>
                                <Col xs="4"><img className="img-fluid px-0 px-lg-5" src="./asset/images/cer-1.png" alt="cer-1" /></Col>
                                <Col xs="4"><img className="img-fluid px-0 px-lg-5" src="./asset/images/cer-2.png" alt="cer-2" /></Col>
                                <Col xs="4"><img className="img-fluid px-0 px-lg-5" src="./asset/images/cer-3.png" alt="cer-3" /></Col>
                            </Row>
                        </Container>
                    </div>
                </section>

                <section className="bg-yellow2 py-2"></section>

                <section className="bg-blue">
                    <Container className="py-5">
                        <Swiper {...params1}>
                            <div onClick={() => this.setState({ index: 0 })} className="px-5">
                                <div className={"hov-bg1 " + (this.state.index === 0 ? "border-green bg-green-dark text-white" : "border-green bg-white text-green")}>
                                    <p className="c-pointer px-3 slide-title">MODULAR CONSOLE DESK</p>
                                </div>
                            </div>
                            <div onClick={() => this.setState({ index: 1 })} className="px-5">
                                <div className={"hov-bg1 " + (this.state.index === 1 ? "border-green bg-green-dark text-white" : "border-green bg-white text-green")}>
                                    <p className="c-pointer px-3 slide-title">INDOOR / OUTDOOR / RAINPROOF STANDARD PANEL</p>
                                </div>
                            </div>
                            <div onClick={() => this.setState({ index: 2 })} className="px-5">
                                <div className={"hov-bg1 " + (this.state.index === 2 ? "border-green bg-green-dark text-white" : "border-green bg-white text-green")}>
                                    <p className="c-pointer px-3 slide-title">PULL BOX & CABLE RACK, INSULATOR</p>
                                </div>
                            </div>
                            <div onClick={() => this.setState({ index: 3 })} className="px-5">
                                <div className={"hov-bg1 " + (this.state.index === 3 ? "border-green bg-green-dark text-white" : "border-green bg-white text-green")}>
                                    <p className="c-pointer px-3 slide-title">CAPACITOR CONTROL PANEL</p>
                                </div>
                            </div>
                            <div onClick={() => this.setState({ index: 4 })} className="px-5">
                                <div className={"hov-bg1 " + (this.state.index === 4 ? "border-green bg-green-dark text-white" : "border-green bg-white text-green")}>
                                    <p className="c-pointer px-3 slide-title">STANDARD DISTRIBUTION BOARD</p>
                                </div>
                            </div>
                            <div onClick={() => this.setState({ index: 5 })} className="px-5">
                                <div className={"hov-bg1 " + (this.state.index === 5 ? "border-green bg-green-dark text-white" : "border-green bg-white text-green")}>
                                    <p className="c-pointer px-3 slide-title">INDOOR MAIN DISTRIBUTION BOARD</p>
                                </div>
                            </div>
                            <div onClick={() => this.setState({ index: 6 })} className="px-5">
                                <div className={"hov-bg1 " + (this.state.index === 6 ? "border-green bg-green-dark text-white" : "border-green bg-white text-green")}>
                                    <p className="c-pointer px-3 slide-title">OUTDOOR MAIN DISTRIBUTION BOARD</p>
                                </div>
                            </div>
                            <div onClick={() => this.setState({ index: 7 })} className="px-5">
                                <div className={"hov-bg1 " + (this.state.index === 7 ? "border-green bg-green-dark text-white" : "border-green bg-white text-green")}>
                                    <p className="c-pointer px-3 slide-title">OUTDOOR LOAD BREAK SWITCH 24KV PANEL</p>
                                </div>
                            </div>
                            <div onClick={() => this.setState({ index: 8 })} className="px-5">
                                <div className={"hov-bg1 " + (this.state.index === 8 ? "border-green bg-green-dark text-white" : "border-green bg-white text-green")}>
                                    <p className="c-pointer px-3 slide-title">WIREWAY</p>
                                </div>
                            </div>
                            <div onClick={() => this.setState({ index: 9 })} className="px-5">
                                <div className={"hov-bg1 " + (this.state.index === 9 ? "border-green bg-green-dark text-white" : "border-green bg-white text-green")}>
                                    <p className="c-pointer px-3 slide-title">CABLE LADDER</p>
                                </div>
                            </div>
                            <div onClick={() => this.setState({ index: 10 })} className="px-5">
                                <div className={"hov-bg1 " + (this.state.index === 10 ? "border-green bg-green-dark text-white" : "border-green bg-white text-green")}>
                                    <p className="c-pointer px-3 slide-title">CABLE TRAY</p>
                                </div>
                            </div>
                            <div onClick={() => this.setState({ index: 11 })} className="px-5">
                                <div className={"hov-bg1 " + (this.state.index === 11 ? "border-green bg-green-dark text-white" : "border-green bg-white text-green")}>
                                    <p className="c-pointer px-3 slide-title">ACCESSORIES FOR CABLE LADDER / TRAY</p>
                                </div>
                            </div>
                            <div onClick={() => this.setState({ index: 12 })} className="px-5">
                                <div className={"hov-bg1 " + (this.state.index === 12 ? "border-green bg-green-dark text-white" : "border-green bg-white text-green")}>
                                    <p className="c-pointer px-3 slide-title">CONTROL PRODUCT (Panel Mounted)</p>
                                </div>
                            </div>
                        </Swiper>
                    </Container>
                </section>

                <section className="p-t-100 p-b-100">
                    <Container>
                        {product(t, this.breakLine)[this.state.index]}
                    </Container>
                </section>
            </div >
        )
    }
}

export default translate()(Product)

const product = (t, breakLine) => [
    <Row>
        <Col xs="12" className="d-flex flex-wrap justify-content-between align-items-center pb-5">
            <img className="img-fluid" src="./asset/images/product/HL-MODULAR CONSOLE DESK.png" width="400px" alt="HL-MODULAR CONSOLE DESK" />
            <p className="pt-3"><a href="./asset/pdf/product/Modular Console Desk.pdf" download><i className="fas fa-download pr-3" />{t('product-download')}</a></p>
        </Col>
        <Col xs="12" className="text-center">
            <Swiper {...params2}>
                <div className="pb-5">
                    <img className="img-fluid" src="./asset/images/product/product_pic/Modular_console.png" width="600px" alt="product" />
                </div>
            </Swiper>
        </Col>
        <Col xs="12" ><p className="pt-4">{t('product-3')}</p></Col>
    </Row>,
    <Row>
        <Col xs="12" className="d-flex flex-wrap justify-content-between align-items-center pb-5">
            <img className="img-fluid" src="./asset/images/product/HL-INDOOR_OUTDOOR_RAINPROOF STANDARD PANEL.png" width="400px" alt="HL-INDOOR_OUTDOOR_RAINPROOF STANDARD PANEL" />
            <p className="pt-3"><a href="./asset/pdf/product/Indoor Outdoor Rainproof Standard Panel.pdf" download><i className="fas fa-download pr-3" />{t('product-download')}</a></p>
        </Col>
        <Col xs="12"><p>{t('product-4')}</p></Col>
        <Col xs="12">
            <h4 className="text-stroke font-weight-bold pb-3 pt-4">INDOOR STANDARD PANEL</h4>
        </Col>
        <Col className="text-center">
            <Swiper {...params2}>
                <div className="pb-5">
                    <img className="img-fluid" src="./asset/images/product/product_pic/indoor_standard_panel.png" width="600px" alt="product" />
                </div>
            </Swiper>
        </Col>
        <Col xs="12">
            <h4 className="text-stroke font-weight-bold pb-3 pt-4">OUTDOOR STANDARD PANEL</h4>
        </Col>
        <Col className="text-center">
            <Swiper {...params2}>
                <div className="pb-5">
                    <img className="img-fluid" src="./asset/images/product/product_pic/outdoor_standard_panel.png" width="600px" alt="product" />
                </div>
            </Swiper>
        </Col>
        <Col xs="12">
            <h4 className="text-stroke font-weight-bold pb-3 pt-4">RAINPROOF STANDARD PANEL</h4>
        </Col>
        <Col className="text-center">
            <Swiper {...params2}>
                <div className="pb-5">
                    <img className="img-fluid" src="./asset/images/product/product_pic/rainproof_standard_panel.png" width="600px" alt="product" />
                </div>
            </Swiper>
        </Col>
    </Row>,
    <Row>
        <Col xs="12" className="d-flex flex-wrap justify-content-between align-items-center pb-5">
            <img className="img-fluid" src="./asset/images/product/HL-PULL BOX & CABLE RACK, INSULATOR.png" width="400px" alt="HL-PULL BOX & CABLE RACK, INSULATOR" />
            <p className="pt-3"><a href="./asset/pdf/product/Pull Box & Cable Rack, Insulator.pdf" download><i className="fas fa-download pr-3" />{t('product-download')}</a></p>
        </Col>
        <Col xs="12" className="text-center">
            <Swiper {...params2}>
                <div className="pb-5">
                    <img className="img-fluid" src="./asset/images/product/product_pic/pull-box.png" width="600px" alt="product" />
                </div>
            </Swiper>
        </Col>
        <Col xs="12"><p className="pt-4">{t('product-5')}</p></Col>
        <Col xs="12">
            <h4 className="text-stroke font-weight-bold pb-3 pt-4">SECONDARY RACKS</h4>
        </Col>
        <Col className="text-center">
            <Swiper {...params2}>
                <div className="pb-5">
                    <img className="img-fluid" src="./asset/images/product/product_pic/secondary_rack.png" width="600px" alt="product" />
                </div>
            </Swiper>
        </Col>
        <Col xs="12"><p className="pt-4">{t('product-6')}</p></Col>
    </Row>,
    <Row>
        <Col xs="12" className="d-flex flex-wrap justify-content-between align-items-center pb-5">
            <img className="img-fluid" src="./asset/images/product/HL-CAPACITOR CONTROL PANEL.png" width="400px" alt="HL-CAPACITOR CONTROL PANEL" />
            <p className="pt-3"><a href="./asset/pdf/product/Capacitor Control Panel.pdf" download><i className="fas fa-download pr-3" />{t('product-download')}</a></p>
        </Col>
        <Col xs="12" className="text-center">
            <Swiper {...params2}>
                <div className="pb-5">
                    <img className="img-fluid" src="./asset/images/product/product_pic/Capacitor Control Panel-1.png" width="600px" alt="product" />
                </div>
                <div className="pb-5">
                    <img className="img-fluid" src="./asset/images/product/product_pic/Capacitor Control Panel-2.png" width="600px" alt="product" />
                </div>
                <div className="pb-5">
                    <img className="img-fluid" src="./asset/images/product/product_pic/Capacitor Control Panel-3.png" width="600px" alt="product" />
                </div>
            </Swiper>
        </Col>
        <Col xs="12"><p className="pt-4">{t('product-7')}</p></Col>
    </Row>,
    <Row>
        <Col xs="12" className="d-flex flex-wrap justify-content-between align-items-center pb-5">
            <img className="img-fluid" src="./asset/images/product/HL-STANDARD DISTRIBUTION BOARD.png" width="400px" alt="HL-STANDARD DISTRIBUTION BOARD" />
            <p className="pt-3"><a href="./asset/pdf/product/Standard Distribution Board.pdf" download><i className="fas fa-download pr-3" />{t('product-download')}</a></p>
        </Col>
        <Col xs="12" className="text-center">
            <Swiper {...params2}>
                <div className="pb-5">
                    <img className="img-fluid" src="./asset/images/product/product_pic/Standard Distribution Board.png" width="600px" alt="product" />
                </div>
            </Swiper>
        </Col>
        <Col xs="12"><p className="pt-4">{t('product-8')}</p></Col>
    </Row>,
    <Row>
        <Col xs="12" className="d-flex flex-wrap justify-content-between align-items-center pb-5">
            <img className="img-fluid" src="./asset/images/product/HL-INDOOR MAIN DISTRIBUTION BOARD.png" width="400px" alt="HL-INDOOR MAIN DISTRIBUTION BOARD" />
            <p className="pt-3"><a href="./asset/pdf/product/Indoor Main Distribution Board.pdf" download><i className="fas fa-download pr-3" />{t('product-download')}</a></p>
        </Col>
        <Col xs="12" className="text-center">
            <Swiper {...params2}>
                <div className="pb-5">
                    <img className="img-fluid" src="./asset/images/product/product_pic/Indoor Main Distribution Board-1.png" width="600px" alt="product" />
                </div>
                <div className="pb-5">
                    <img className="img-fluid" src="./asset/images/product/product_pic/Indoor Main Distribution Board-2.png" width="600px" alt="product" />
                </div>
            </Swiper>
        </Col>
        <Col xs="12"><p className="pt-4">{breakLine(t('product-9'))}</p></Col>
    </Row>,
    <Row>
        <Col xs="12" className="d-flex flex-wrap justify-content-between align-items-center pb-5">
            <img className="img-fluid" src="./asset/images/product/HL-OUTDOOR MAIN DISTRIBUTION BOARD.png" width="400px" alt="HL-OUTDOOR MAIN DISTRIBUTION BOARD" />
            <p className="pt-3"><a href="./asset/pdf/product/Outdoor Main Distribution Board.pdf" download><i className="fas fa-download pr-3" />{t('product-download')}</a></p>
        </Col>
        <Col xs="12" className="text-center">
            <Swiper {...params2}>
                <div className="pb-5">
                    <img className="img-fluid" src="./asset/images/product/product_pic/Outdoor Main Distribution Board.png" width="600px" alt="product" />
                </div>
            </Swiper>
        </Col>
        <Col xs="12"><p className="pt-4">{breakLine(t('product-10'))}</p></Col>
    </Row>,
    <Row>
        <Col xs="12" className="d-flex flex-wrap justify-content-between align-items-center pb-5">
            <img className="img-fluid" src="./asset/images/product/HL-OUTDOOR LOAD BREAK SWITCH 24KV PANEL.png" width="400px" alt="HL-OUTDOOR LOAD BREAK SWITCH 24KV PANEL" />
            <p className="pt-3"><a href="./asset/pdf/product/Outdoor Load Break Switch 24KV Panel.pdf" download><i className="fas fa-download pr-3" />{t('product-download')}</a></p>
        </Col>
        <Col xs="12" className="text-center">
            <Swiper {...params2}>
                <div className="pb-5">
                    <img className="img-fluid" src="./asset/images/product/product_pic/Outdoor Load Break Switch 24KV Panel-2.png" width="600px" alt="product" />
                </div>
            </Swiper>
        </Col>
        <Col xs="12"><p className="pt-4">{t('product-11')}</p></Col>

    </Row>,
    <Row>
        <Col className="d-flex flex-wrap justify-content-between align-items-center pb-5">
            <img className="img-fluid" src="./asset/images/product/HL-WIREWAY.png" width="400px" alt="HL-WIREWAY" />
            <p className="pt-3"><a href="./asset/pdf/product/Wireway.pdf" download><i className="fas fa-download pr-3" />{t('product-download')}</a></p>
        </Col>
        <Col xs="12"><p>{t('product-12')}</p></Col>
        <Col className="text-center pt-4">
            <Swiper {...params2}>
                <div className="pb-5">
                    <img className="img-fluid" src="./asset/images/product/product_pic/Wireway.png" width="600px" alt="product" />
                    <p className="pt-3">WIRE WAY (AWP TYPE)</p>
                </div>
                <div className="pb-5">
                    <img className="img-fluid" src="./asset/images/product/product_pic/Wireway_vertical-bend-inside-with-cover.png" width="600px" alt="product" />
                    <p className="pt-3">90° VERTICAL INSIDE BEND WITH COVER</p>
                </div>
                <div className="pb-5">
                    <img className="img-fluid" src="./asset/images/product/product_pic/Wireway_vertical-bend-outside-with-cover.png" width="600px" alt="product" />
                    <p className="pt-3">90° VERTICAL OUTSIDE BEND WITH COVER</p>
                </div>
                <div className="pb-5">
                    <img className="img-fluid" src="./asset/images/product/product_pic/Wireway_horizontal-bend-with-cover.png" width="600px" alt="product" />
                    <p className="pt-3">90° HORIZONTAL INSIDE BEND WITH COVER</p>
                </div>
                <div className="pb-5">
                    <img className="img-fluid" src="./asset/images/product/product_pic/Wireway_horizontal-reduce-with-cover.png" width="600px" alt="product" />
                    <p className="pt-3">HORIZONTAL REDUCE WITH COVER</p>
                </div>
                <div className="pb-5">
                    <img className="img-fluid" src="./asset/images/product/product_pic/Wireway_horizontal-tee-reduce-with-cover.png" width="600px" alt="product" />
                    <p className="pt-3">HORIZONTAL TEE WITH COVER</p>
                </div>
                <div className="pb-5">
                    <img className="img-fluid" src="./asset/images/product/product_pic/Wireway_horizontal-cross(x)-with-cover.png" width="600px" alt="product" />
                    <p className="pt-3">HORIZONTAL CROSS(x) WITH COVER</p>
                </div>
                <div className="pb-5">
                    <img className="img-fluid" src="./asset/images/product/product_pic/Wireway_horizontal-tee-reduce-with-cover.png" width="600px" alt="product" />
                    <p className="pt-3">HORIZONTAL TEE REDUCE WITH COVER</p>
                </div>
                <div className="pb-5">
                    <img className="img-fluid" src="./asset/images/product/product_pic/Wireway_horizontal-cross-reduce-with-cover.png" width="600px" alt="product" />
                    <p className="pt-3">HORIZONTAL CROSS-REDUCE WITH COVER</p>
                </div>
                <div className="pb-5">
                    <img className="img-fluid" src="./asset/images/product/product_pic/Wireway-connector.png" width="600px" alt="product" />
                    <p className="pt-3">connector</p>
                </div>
                <div className="pb-5">
                    <img className="img-fluid" src="./asset/images/product/product_pic/Wireway-end-cap.png" width="600px" alt="product" />
                    <p className="pt-3">END CAP</p>
                </div>
                <div className="pb-5">
                    <img className="img-fluid" src="./asset/images/product/product_pic/Wireway-bracket-support.png" width="600px" alt="product" />
                    <p className="pt-3">BRACKET SUPPORT(WALL MOUNTED)</p>
                </div>
                <div className="pb-5">
                    <img className="img-fluid" src="./asset/images/product/product_pic/Wireway-hanger-support.png" width="600px" alt="product" />
                    <p className="pt-3">HANGER SUPPORT</p>
                </div>
            </Swiper>
        </Col>
    </Row>,
    <Row>
        <Col className="d-flex flex-wrap justify-content-between align-items-center pb-5">
            <img className="img-fluid" src="./asset/images/product/HL-CABLE LADDER.png" width="400px" alt="HL-CABLE LADDER" />
            <p className="pt-3"><a href="./asset/pdf/product/Cable Ladder.pdf" download><i className="fas fa-download pr-3" />{t('product-download')}</a></p>
        </Col>
        <Col xs="12"><p>{t('product-13')}</p></Col>
        <Col className="text-center pt-4">
            <Swiper {...params2}>
                <div className="pb-5">
                    <img className="img-fluid" src="./asset/images/product/product_pic/cable-ladder.png" width="600px" alt="product" />
                    <p className="pt-3">CABLE LADDER (ALH TYPE)</p>
                </div>
                <div className="pb-5">
                    <img className="img-fluid" src="./asset/images/product/product_pic/cable-ladder-horizontal-reduce.png" width="600px" alt="product" />
                    <p className="pt-3">HORIZONTAL REDUCE</p>
                </div>
                <div className="pb-5">
                    <img className="img-fluid" src="./asset/images/product/product_pic/cable-ladder-vertical-outside-bend.png" width="600px" alt="product" />
                    <p className="pt-3">90° VERTICAL OUTSIDE BEND</p>
                </div>
                <div className="pb-5">
                    <img className="img-fluid" src="./asset/images/product/product_pic/cable-ladder-vertical-inside-bend.png" width="600px" alt="product" />
                    <p className="pt-3">90° VERTICAL INSIDE BEND</p>
                </div>
                <div className="pb-5">
                    <img className="img-fluid" src="./asset/images/product/product_pic/cable-ladder-horizontal-bend.png" width="600px" alt="product" />
                    <p className="pt-3">90° HORIZONTAL BEND</p>
                </div>
                <div className="pb-5">
                    <img className="img-fluid" src="./asset/images/product/product_pic/cable-ladder-horizontal-tee.png" width="600px" alt="product" />
                    <p className="pt-3">HORIZONTAL TEE</p>
                </div>
                <div className="pb-5">
                    <img className="img-fluid" src="./asset/images/product/product_pic/cable-ladder-horizontal-cross.png" width="600px" alt="product" />
                    <p className="pt-3">HORIZONTAL CROSS</p>
                </div>
            </Swiper>
        </Col>
    </Row>,
    <Row>
        <Col className="d-flex flex-wrap justify-content-between align-items-center pb-5">
            <img className="img-fluid" src="./asset/images/product/HL-CABLE TRAY.png" width="400px" alt="HL-CABLE TRAY" />
            <p className="pt-3"><a href="./asset/pdf/product/Cable Tray.pdf" download><i className="fas fa-download pr-3" />{t('product-download')}</a></p>
        </Col>
        <Col xs="12"><p>{t('product-14')}</p></Col>
        <Col className="text-center pt-4">
            <Swiper {...params2}>
                <div className="pb-5">
                    <img className="img-fluid" src="./asset/images/product/product_pic/cable-tray.png" width="600px" alt="product" />
                    <p className="pt-3">CABLE TRAY (ATE TYPE)</p>
                </div>
                <div className="pb-5">
                    <img className="img-fluid" src="./asset/images/product/product_pic/cable-tray-horizontal-reduce.png" width="600px" alt="product" />
                    <p className="pt-3">HORIZONTAL REDUCE</p>
                </div>
                <div className="pb-5">
                    <img className="img-fluid" src="./asset/images/product/product_pic/cable-tray-vertical-outside-bend.png" width="600px" alt="product" />
                    <p className="pt-3">90° VERTICAL OUTSIDE BEND</p>
                </div>
                <div className="pb-5">
                    <img className="img-fluid" src="./asset/images/product/product_pic/cable-tray-vertical-inside-bend.png" width="600px" alt="product" />
                    <p className="pt-3">90° VERTICAL INSIDE BEND</p>
                </div>
                <div className="pb-5">
                    <img className="img-fluid" src="./asset/images/product/product_pic/cable-tray-horizontal-bend.png" width="600px" alt="product" />
                    <p className="pt-3">90° HORIZONTAL BEND</p>
                </div>
                <div className="pb-5">
                    <img className="img-fluid" src="./asset/images/product/product_pic/cable-tray-horizontal-tee.png" width="600px" alt="product" />
                    <p className="pt-3">HORIZONTAL TEE</p>
                </div>
                <div className="pb-5">
                    <img className="img-fluid" src="./asset/images/product/product_pic/cable-tray-horizontal-cross.png" width="600px" alt="product" />
                    <p className="pt-3">HORIZONTAL CROSS</p>
                </div>
                <div className="pb-5">
                    <img className="img-fluid" src="./asset/images/product/product_pic/cable-tray-end-plate_for-cable-tray.png" width="600px" alt="product" />
                    <p className="pt-3">END PPLATE FOR CABLE TRAY</p>
                </div>
                <div className="pb-5">
                    <img className="img-fluid" src="./asset/images/product/product_pic/cable-tray-end-plate_for-cable-ladder.png" width="600px" alt="product" />
                    <p className="pt-3">END PPLATE FOR CABLE LADDER</p>
                </div>
            </Swiper>
        </Col>
    </Row>,
    <Row>
        <Col className="d-flex flex-wrap justify-content-between align-items-center pb-5">
            <img className="img-fluid" src="./asset/images/product/HL-ACCESSORIES FOR CABLE LADDER_TRAY.png" width="400px" alt="HL-ACCESSORIES FOR CABLE LADDER_TRAY" />
            <p className="pt-3"><a href="./asset/pdf/product/Accessories for Cable Ladder Tray.pdf" download><i className="fas fa-download pr-3" />{t('product-download')}</a></p>
        </Col>
        <Col xs="12"><p>{t('product-15')}</p></Col>
        <Col xs="12">
            <h4 className="text-stroke font-weight-bold pb-3 pt-4">ACCESSORIES</h4>
        </Col>
        <Col className="text-center">
            <Swiper {...params2}>
                <div className="pb-5">
                    <img className="img-fluid" src="./asset/images/product/product_pic/accessories-flange-end.png" width="600px" alt="product" />
                </div>
                <div className="pb-5">
                    <img className="img-fluid" src="./asset/images/product/product_pic/accessories-j-lock.png" width="600px" alt="product" />
                </div>
                <div className="pb-5">
                    <img className="img-fluid" src="./asset/images/product/product_pic/accessories-splice-plate.png" width="600px" alt="product" />
                </div>
                <div className="pb-5">
                    <img className="img-fluid" src="./asset/images/product/product_pic/accessories-horizontal-adjustable.png" width="600px" alt="product" />
                </div>
                <div className="pb-5">
                    <img className="img-fluid" src="./asset/images/product/product_pic/accessories-vertical-adjustable.png" width="600px" alt="product" />
                </div>
                <div className="pb-5">
                    <img className="img-fluid" src="./asset/images/product/product_pic/accessories-hold-down-clamp.png" width="600px" alt="product" />
                </div>
                <div className="pb-5">
                    <img className="img-fluid" src="./asset/images/product/product_pic/accessories-u-channel(for-j-lock).png" width="600px" alt="product" />
                </div>
            </Swiper>
        </Col>
        <Col xs="12">
            <h4 className="text-stroke font-weight-bold pb-3 pt-4">BRACKET SUPPORT</h4>
        </Col>
        <Col className="text-center">
            <Swiper {...params2}>
                <div className="pb-5">
                    <img className="img-fluid" src="./asset/images/product/product_pic/accessories-bracket-support.png" width="600px" alt="product" />
                </div>
            </Swiper>
        </Col>
        <Col xs="12">
            <h4 className="text-stroke font-weight-bold pb-3 pt-4">HANGING SUPPORT</h4>
        </Col>
        <Col className="text-center">
            <Swiper {...params2}>
                <div className="pb-5">
                    <img className="img-fluid" src="./asset/images/product/product_pic/accessories-hanging-support.png" width="600px" alt="product" />
                </div>
            </Swiper>
        </Col>
    </Row>,
    <Row>
        <Col className="d-flex flex-wrap justify-content-between align-items-center pb-5">
            <img className="img-fluid" src="./asset/images/product/HL-CONTROL PRODUCT (Panel Mounted).png" width="400px" alt="HL-CONTROL PRODUCT (Panel Mounted)" />
            <p className="pt-3"><a href="./asset/pdf/product/Control Product (Panel Mounted).pdf" download><i className="fas fa-download pr-3" />{t('product-download')}</a></p>
        </Col>
        <Col xs="12"><p>{t('product-16')}</p></Col>
        <Col className="text-center pt-4">
            <Swiper {...params2}>
                <div className="pb-5">
                    <img className="img-fluid" src="./asset/images/product/product_pic/A&A-product01.png" width="300px" alt="product" />
                </div>
                <div className="pb-5">
                    <img className="img-fluid" src="./asset/images/product/product_pic/A&A-product02.png" width="300px" alt="product" />
                </div>
                <div className="pb-5">
                    <img className="img-fluid" src="./asset/images/product/product_pic/A&A-product03.png" width="300px" alt="product" />
                </div>
                <div className="pb-5">
                    <img className="img-fluid" src="./asset/images/product/product_pic/A&A-product04.png" width="300px" alt="product" />
                </div>
                <div className="pb-5">
                    <img className="img-fluid" src="./asset/images/product/product_pic/A&A-product05.png" width="300px" alt="product" />
                </div>
                <div className="pb-5">
                    <img className="img-fluid" src="./asset/images/product/product_pic/A&A-product06.png" width="300px" alt="product" />
                </div>
                <div className="pb-5">
                    <img className="img-fluid" src="./asset/images/product/product_pic/A&A-product07.png" width="300px" alt="product" />
                </div>
            </Swiper>
        </Col>
    </Row>
]