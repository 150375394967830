import React, { Component } from 'react'
import './style.scss'
import { Container, Row, Col, Button } from 'reactstrap'
import Swiper from 'react-id-swiper'
import 'react-id-swiper/src/styles/css/swiper.css'
import Pagination from 'rc-pagination'
import '../../style/pagination.scss'
import { Link } from 'react-router-dom'
import { translate } from 'react-i18next'

const params = {
    slidesPerView: 4,
    spaceBetween: 20,
    slidesPerGroup: 4,
    autoplay: {
        delay: 5000,
        disableOnInteraction: false
    },
    pagination: {
        el: '.swiper-pagination',
        clickable: true
    },
    breakpoints: {
        991.98: { slidesPerView: 3 },
        575.98: { slidesPerView: 2 }
    }
}

class Reference extends Component {
    constructor(props) {
        super(props)
        this.state = {
            current: 1,
            pageSize: 5
        }
    }

    onChange = (page) => {
        window.scrollTo(0, 0)
        this.setState({ current: page })
    }

    render() {
        const { t } = this.props
        return (
            <div>
                <section className="p-t-56">
                    <div className="bg-image-ref screen50 d-flex align-items-center">
                        <Container>
                            <Row className="align-items-center flex-wrap-reverse text-green">
                                <Col md="6">
                                    <h2 className="font-weight-bold text-white">A&A CONTROL</h2>
                                    <p className="py-4 text-white">{t('home-1')}</p>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </section>

                <section className="p-t-100 p-b-100">
                    <Container className="py-4">
                        <Col className="text-center pt-5">
                            <img className="img-fluid" src="./asset/images/ref/HL-site-ref-1.png" width="400px" alt="HL-site-ref-1" />
                        </Col>
                        <Swiper {...params}>
                            <img className="px-2 px-md-4 img-fluid" src="./asset/images/ref/SCG.png" alt="SCG" />
                            <img className="px-2 px-md-4 img-fluid" src="./asset/images/ref/bkk_hospital.png" alt="bkk_hospital" />
                            <img className="px-2 px-md-4 img-fluid" src="./asset/images/ref/ptt_chem.png" alt="ptt_chem" />
                            <img className="px-2 px-md-4 img-fluid" src="./asset/images/ref/true.png" alt="true" />
                            <img className="px-2 px-md-4 img-fluid" src="./asset/images/ref/Thai_air.png" alt="Thai_air" />
                            <img className="px-2 px-md-4 img-fluid" src="./asset/images/ref/กฟผ.png" alt="กฟผ" />
                            <img className="px-2 px-md-4 img-fluid" src="./asset/images/ref/AOT.png" alt="AOT" />
                            <img className="px-2 px-md-4 img-fluid" src="./asset/images/ref/dusit.png" alt="dusit" />
                            <img className="px-2 px-md-4 img-fluid" src="./asset/images/ref/phyathai.png" alt="phyathai" />
                        </Swiper>
                    </Container>
                </section>

                <section>
                    <Container>
                        <hr />
                    </Container>
                </section>

                <section className="p-t-100 p-b-100">
                    <Container>
                        <Col className="text-center pb-5">
                            <img className="img-fluid" src="./asset/images/ref/HL-site-ref-2.png" width="400px" alt="HL-site-ref-2" />
                        </Col>
                        {this.props.items.sort((item1, item2) => item1.id < item2.id ? 1 : -1).map((items, index) => {
                            if (index >= ((this.state.current - 1) * this.state.pageSize) && index < (((this.state.current - 1) * this.state.pageSize) + this.state.pageSize)) {
                                return (
                                    <Row key={`items-${index}`} className="mx-1 my-2">
                                        <Col md="4" className="px-0 text-center"><img className="img-fluid" src={items.image} alt={items.project} /></Col>
                                        <Col md="8" className="px-0 px-md-4 pt-4 pt-md-0">
                                            <h5 className="pb-3 font-weight-bold">{items.project}</h5>
                                            <p className="pb-1"><strong>{t("reference-2")} </strong> {items.customer}</p>
                                            <p className="pb-1"><strong>{t("reference-3")} </strong> {items.process}</p>
                                            <p className="pb-1"><strong>{t("reference-4")} </strong> {items.time}</p>
                                            <Link to={{ pathname: "/reference", search: "?id=" + items.id }}>
                                                <Button className="px-4 py-3 mt-3 btn-blue">{t('more-btn')}</Button>
                                            </Link>
                                        </Col>
                                        <Col xs="12" className="px-0">
                                            <hr className="bg-green-dark" />
                                        </Col>
                                    </Row>
                                )
                            } else {
                                return ('')
                            }
                        })}
                        <Col className="d-flex align-items-center justify-content-end">
                            <h6 className="pr-2">{t('new-4')}</h6>
                            <Pagination
                                className="pt-3"
                                onChange={this.onChange}
                                current={this.state.current}
                                total={this.props.items.length}
                                pageSize={this.state.pageSize}
                                showLessItems={true} />
                        </Col>
                    </Container>
                </section>
            </div>
        )
    }
}

export default translate()(Reference)