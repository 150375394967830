import React, { Component } from 'react'
import Swiper from 'react-id-swiper'
import 'react-id-swiper/src/styles/css/swiper.css'
import { Container, Row, Col, Button } from 'reactstrap'
import Loading from '../loading'
import axios from 'axios'
import Service from '../../service.js'
import { Link } from 'react-router-dom'
import { translate } from 'react-i18next'

const params1 = {
    slidesPerView: 1,
    spaceBetween: 20,
    autoplay: {
        delay: 5000,
        disableOnInteraction: false
    },
    shouldSwiperUpdate: true,
    pagination: {
        el: '.swiper-pagination',
        clickable: true
    }
}

const params2 = {
    slidesPerView: 4,
    spaceBetween: 20,
    slidesPerGroup: 4,
    autoplay: {
        delay: 5000,
        disableOnInteraction: false
    },
    pagination: {
        el: '.swiper-pagination',
        clickable: true
    },
    breakpoints: {
        991.98: { slidesPerView: 3 },
        575.98: { slidesPerView: 2 }
    }
}

class Home extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            heightService: '',
            itemsProduct: [],
            itemsReference: [],
            itemsNews: [],
            activeIndex: 0,
            scroll: 0,
            height: 0,
            product_scroll: {},
            news_events: {},
            iso_scroll: {},
            control_scroll: {},
            slogan_scroll: {},
        }
    }

    componentDidMount() {
        this.updateWindowDimensions()
        window.addEventListener('scroll', this.updateWindowDimensions)
        axios.all([
            Service.getProduct(),
            Service.getReference(),
            Service.getNews()
        ])
            .then(axios.spread((itemsProduct, itemsReference, itemsNews) => {
                this.setState({
                    loading: false,
                    itemsProduct: itemsProduct.data.data,
                    itemsReference: itemsReference.data.data,
                    itemsNews: itemsNews.data.data
                })
            }))
            .catch(error => {
                console.log(error)
            })
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.updateWindowDimensions)
    }

    updateWindowDimensions = () => {
        this.setState({
            scroll: window.scrollY,
            height: window.innerHeight,
            product_scroll: document.getElementById("product").getBoundingClientRect(),
            news_events: document.getElementById("news-events").getBoundingClientRect(),
            iso_scroll: document.getElementById("iso").getBoundingClientRect(),
            control_scroll: document.getElementById("control").getBoundingClientRect(),
            slogan_scroll: document.getElementById("slogan").getBoundingClientRect()
        })
    }

    onExiting = () => {
        this.animating = true;
    }

    onExited = () => {
        this.animating = false;
    }

    goToIndex = (newIndex) => {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }

    breakLine = (text) => {
        var br = React.createElement('br');
        var regex = /(<br\/>)/g;
        return text.split(regex).map(function (line, index) {
            return line.match(regex) ? <br key={"key_" + index} /> : line;
        })
    }

    render() {
        const { t } = this.props
        return (
            <div>
                <Loading loading={this.state.loading} />
                <section className="p-t-56">
                    <div className="bg-g-tb">
                        <div className="frame p-t-100 p-b-100">
                            <Container>
                                <Row className="align-items-center flex-wrap-reverse text-green">
                                    <Col md="5">
                                        <h2 className="font-weight-bold py-2">A&A CONTROL</h2>
                                        <p className="py-2">{t('home-1')}</p>
                                        <Link to="/about"><Button color="" className="px-4 py-3 mt-3 btn-blue">{t('more-btn')}<i className="fas fa-angle-right pl-2"></i></Button></Link>
                                    </Col>
                                    <Col md="7">
                                        <Swiper {...params1}>
                                            <img src="./asset/images/banner1.png" alt="banner" />
                                            <img src="./asset/images/banner2.png" alt="banner" />
                                            <img src="./asset/images/banner3.png" alt="banner" />
                                        </Swiper>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                </section>

                <section className="p-t-100 p-b-100 bg-g-green">
                    <Container className="pb-5">
                        <Row className="py-0 py-lg-5 align-items-center">
                            <Col lg="6">
                                <img className="img-fluid img-border rounded shadow-lg" src="./asset/images/about.jpg" alt="about" />
                            </Col>
                            <Col lg="6" className="pt-5 px-4 pt-lg-0">
                                <div className="text-center px-2"><img className="img-fluid" src="./asset/images/home/HL-01.png" width="400px" alt="HL-01" /></div>
                                <div className="py-4">
                                    <p className="pb-3">{this.breakLine(t('home-2'))}</p>
                                </div>
                                <Link to="/about"><Button color="" className="px-4 py-3 btn-blue">{t('more-btn')}<i className="fas fa-angle-right pl-2"></i></Button></Link>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className="m-minus-30 position-relative" id="slogan">
                    <div className={"diamond bg-green-dark pos-absolute point-green-r" + ((this.state.slogan_scroll.top - ((this.state.height / 2) + (this.state.height / 3))) <= 0 ? " animated-none fadeInLeft" : " animated fadeOutLeft")}></div>
                    <div className={"diamond bg-green-dark pos-absolute point-green-l" + ((this.state.slogan_scroll.top - ((this.state.height / 2) + (this.state.height / 3))) <= 0 ? " animated-none fadeInRight" : " animated fadeOutRight")}></div>
                    <div className={"diamond bg-yellow pos-absolute point-yellow-l " + ((this.state.slogan_scroll.top - ((this.state.height / 2) + (this.state.height / 3))) <= 0 ? " animated-none fadeInRight" : " animated fadeOutRight")}></div>
                    <Container>
                        <Row>
                            <Col className="text-green">
                                <div className="text-center diamond-border-green px-5 py-3 py-md-5 mx-auto">
                                    <h4 className="d-27 head-title font-weight-bold">“{t('home-3')}”</h4>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className="bg-yellow p-t-100 p-b-100" id="control">
                    <Container>
                        <Row>
                            <Col xs="12" className="text-center">
                                <img className="img-fluid pr-2" src="./asset/images/home/HL-02.png" width="400px" alt="HL-02" />
                            </Col>
                            <Col xs="12">
                                <p className="py-4 text-indent">{t('home-4')}</p>
                            </Col>
                            <Col className="d-flex flex-wrap text-green justify-content-around text-center px-0">
                                <Col xs="4" md="2" className={"py-2 animated " + ((this.state.control_scroll.top - (this.state.height / 2)) <= 0 ? "fadeIn" : "fadeOut")}>
                                    <img className="img-fluid" src="./asset/images/icon/engineering_icon.png" alt="engineering_icon" />
                                    <h6 className="pt-2">{t('home-5')}</h6>
                                </Col>
                                <Col xs="4" md="2" className={"py-2 animated " + ((this.state.control_scroll.top - (this.state.height / 2)) <= 0 ? "fadeIn" : "fadeOut")}>
                                    <img className="img-fluid" src="./asset/images/icon/installation_icon.png" alt="installation_icon" />
                                    <h6 className="pt-2">{t('home-6')}</h6>
                                </Col>
                                <Col xs="4" md="2" className={"py-2 animated " + ((this.state.control_scroll.top - (this.state.height / 2)) <= 0 ? "fadeIn" : "fadeOut")}>
                                    <img className="img-fluid  fadeIn" src="./asset/images/icon/supervision_icon.png" alt="supervision_icon" />
                                    <h6 className="pt-2">{t('home-7')}</h6>
                                </Col>
                                <Col xs="4" md="2" className={"py-2 animated " + ((this.state.control_scroll.top - (this.state.height / 2)) <= 0 ? "fadeIn" : "fadeOut")}>
                                    <img className="img-fluid" src="./asset/images/icon/testing_icon.png" alt="testing_icon" />
                                    <h6 className="pt-2">{t('home-8')}</h6>
                                </Col>
                                <Col xs="4" md="2" className={"py-2 animated " + ((this.state.control_scroll.top - (this.state.height / 2)) <= 0 ? "fadeIn" : "fadeOut")}>
                                    <img className="img-fluid" src="./asset/images/icon/maintainance_icon.png" alt="maintainance_icon" />
                                    <h6 className="pt-2">{t('home-9')}</h6>
                                </Col>
                                <Col xs="4" md="2" className={"py-2 animated " + ((this.state.control_scroll.top - (this.state.height / 2)) <= 0 ? "fadeIn" : "fadeOut")}>
                                    <img className="img-fluid" src="./asset/images/icon/customerservice_icon.png" alt="customerservice_icon" />
                                    <h6 className="pt-2">{t('home-10')}</h6>
                                </Col>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className="p-t-100 p-b-100 bg-image1" id="iso">
                    <Container>
                        <Row>
                            <Col xs="12" className="text-center text-white pt-3 pb-2 mb-1">
                                <h2><strong>ISO 9001 : 2008</strong></h2>
                                <p className="py-3">{t('home-11')}</p>
                            </Col>
                        </Row>
                        <Row className="text-center">
                            <Col xs="4" className="px-2 px-sm-3">
                                <img src="./asset/images/logo_cer-1.png" className={"img-fluid rounded-circle animated px-3 " + ((this.state.iso_scroll.top - ((this.state.height / 2) + (this.state.height / 3))) <= 0 ? "fadeIn" : "fadeOut")} width="170px" alt="logo_cer-1" />
                            </Col>
                            <Col xs="4" className="px-2 px-sm-3">
                                <img src="./asset/images/logo_cer-2.png" className={"img-fluid rounded-circle  animated px-3 " + ((this.state.iso_scroll.top - ((this.state.height / 2) + (this.state.height / 3))) <= 0 ? "fadeIn" : "fadeOut")} width="170px" alt="logo_cer-2" />
                            </Col>
                            <Col xs="4" className="px-2 px-sm-3">
                                <img src="./asset/images/logo_cer-3.png" className={"img-fluid rounded-circle animated px-3 " + ((this.state.iso_scroll.top - ((this.state.height / 2) + (this.state.height / 3))) <= 0 ? "fadeIn" : "fadeOut")} width="170px" alt="logo_cer-3" />
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className="p-t-100 p-b-100 bg-g-tb">
                    <div className={((this.state.product_scroll.top - ((this.state.height / 2) + (this.state.height / 3))) <= 0 ? "animated fadeIn" : "animated fadeOut")} id="product">
                        <Container>
                            <Row>
                                <Col xs="12" className="text-center">
                                    <img className="img-fluid pr-2" src="./asset/images/home/HL-03.png" width="400px" alt="HL-03" />
                                </Col>
                                <Row className="text-center px-0 pt-4">
                                    <Col xs="6" md="3" className={"px-4 px-lg-5 py-2 " + ((this.state.product_scroll.top - ((this.state.height / 2) + (this.state.height / 3))) <= 0 ? "animated delay-1s fadeIn" : "animated fadeOut")}>
                                        <img src="./asset/images/dealer1.png" className="img-fluid px-4 px-md-2 pb-2" alt="abb" />
                                        <a href="./asset/pdf/sale/abb.pdf" target="_blank" rel="noopener noreferrer"><u>{t('more-btn')}</u></a>
                                    </Col>
                                    <Col xs="6" md="3" className={"px-4 px-lg-5 py-2 " + ((this.state.product_scroll.top - ((this.state.height / 2) + (this.state.height / 3))) <= 0 ? "animated delay-1s fadeIn" : "animated fadeOut")}>
                                        <img src="./asset/images/dealer2.png" className="img-fluid px-4 px-md-2 pb-2" alt="lg" />
                                        <a href="./asset/pdf/sale/lg.pdf" target="_blank" rel="noopener noreferrer"><u>{t('more-btn')}</u></a>
                                    </Col>
                                    <Col xs="6" md="3" className={"px-4 px-lg-5 py-2 " + ((this.state.product_scroll.top - ((this.state.height / 2) + (this.state.height / 3))) <= 0 ? "animated delay-1s fadeIn" : "animated fadeOut")}>
                                        <img src="./asset/images/dealer3.png" className="img-fluid px-4 px-md-2 pb-2" alt="mitsubishi" />
                                        <a href="./asset/pdf/sale/mitsubishi.pdf" target="_blank" rel="noopener noreferrer"><u>{t('more-btn')}</u></a>
                                    </Col>
                                    <Col xs="6" md="3" className={"px-4 px-lg-5 py-2 " + ((this.state.product_scroll.top - ((this.state.height / 2) + (this.state.height / 3))) <= 0 ? "animated delay-1s fadeIn" : "animated fadeOut")}>
                                        <img src="./asset/images/dealer4.png" className="img-fluid px-4 px-md-2 pb-2" alt="schneider" />
                                        <a href="./asset/pdf/sale/schneider.pdf" target="_blank" rel="noopener noreferrer"><u>{t('more-btn')}</u></a>
                                    </Col>
                                </Row>
                            </Row>
                        </Container>
                    </div>
                </section>

                <section className="p-t-100 p-b-100 bg-image4">
                    <Container className="py-5">
                        <Row>
                            <Col xs="12" className="text-center">
                                <img className="img-fluid pr-2" src="./asset/images/home/HL-04.png" width="400px" alt="HL-04" />
                            </Col>
                            <Col xs="12" className="pt-5">
                                <Swiper {...params1}>
                                    {this.state.itemsProduct.map((items, index) => {
                                        return (
                                            <div key={`items-${index}`}>
                                                <Row className="pb-5">
                                                    <Col lg="5">
                                                        <div className="text-center">
                                                            <img className="img-fluid rounded shadow-lg" src={items.image} alt={items.title} />
                                                        </div>
                                                    </Col>
                                                    <Col lg="7" className="pt-4 pt-lg-0">
                                                        <h5 className="font-weight-bold pb-4">{items.title}</h5>
                                                        <div className="pb-4">
                                                            <p className="font-weight-bold pb-2">{items.type === "product" ? t('home-12') : t('home-12-2')}</p>
                                                            {this.props.App.Language === "th"
                                                                ? this.breakLine(items.detailmoreth)
                                                                : this.breakLine(items.detailmoreen)
                                                            }
                                                        </div>
                                                        <Link to={{ pathname: items.type === "product" ? "/product" : "/service", state: { index: index } }}>
                                                            <Button className="px-4 py-3 btn-blue">{t('more-btn')}</Button>
                                                        </Link>
                                                    </Col>
                                                </Row>
                                            </div>
                                        )
                                    })}
                                </Swiper>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className="py-4">
                    <Container>
                        <Col className="d-flex flex-wrap justify-content-center justify-content-md-between align-items-center px-0">
                            <h4 className="font-weight-bold text-center my-3 pr-3">{t('home-13')} <i className="fas fa-chevron-circle-right pl-2"></i></h4>
                            <Link to="/product"><Button color="info" className="px-4 py-3 my-3 btn-blue">{t('more-btn')}</Button></Link>
                        </Col>
                    </Container>
                </section>

                <section>
                    <div className="bg-image3">
                        <Container>
                            <Row className="height-fullscreen">
                                <Col xs="12" md="7" lg="6" xl="5" className="my-auto text-white">
                                    <h2 className="font-weight-bold">{t('home-14')}</h2>
                                    <p className="py-4">{t('home-15')}</p>
                                    <Link to="/service"><Button color="" className="px-4 py-3 btn-blue">{t('more-btn')} <i className="fas fa-angle-right pl-2"></i></Button></Link>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </section>

                <section className="bg-image1">
                    <div className="bg-white-transparent">
                        <Container className="p-t-100 p-b-100">
                            <Row>
                                <Col xs="12" className="text-center">
                                    <img className="img-fluid pr-2" src="./asset/images/home/HL-05.png" width="400px" alt="HL-05" />
                                </Col>
                                <Col xs="12" className="py-5">
                                    <Row>
                                        {this.state.itemsReference.sort((item1, item2) => item1.id < item2.id ? 1 : -1).map((items, index) => {
                                            if (index < 6) {
                                                return (
                                                    <Col xs="12" md="6" lg="4" className="grid m-0 p-1" key={`items-${index}`}>
                                                        <figure className="effect-sarah m-0">
                                                            <img className="img-fluid" src={items.image} alt="img02" />
                                                            <figcaption>
                                                                <h4 className="font-weight-bold text-white">{items.project}</h4>
                                                                <p className="pt-2">{t('home-16')} : {items.customer}</p>
                                                                <p>{t('home-17')} : {items.process}</p>
                                                                <p>{t('home-18')} : {items.time}</p>
                                                                <Link to={{ pathname: "/reference", search: "?id=" + items.id }}></Link>
                                                            </figcaption>
                                                        </figure>
                                                    </Col>
                                                )
                                            } else {
                                                return ("")
                                            }
                                        })}
                                    </Row>
                                </Col>
                                <Col xs="12" className="text-center">
                                    <Link to="/reference"><Button className="px-4 py-3 btn-blue">{t('more-btn')} <i className="fas fa-angle-right pl-2"></i></Button></Link>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </section>

                <section className="py-5 bg-white">
                    <Container className="py-0 py-md-5">
                        <Row>
                            <Col xs="12">
                                <Swiper {...params2}>
                                    <img className="px-2 px-md-4 img-fluid" src="./asset/images/ref/SCG.png" alt="SCG" />
                                    <img className="px-2 px-md-4 img-fluid" src="./asset/images/ref/bkk_hospital.png" alt="bkk_hospital" />
                                    <img className="px-2 px-md-4 img-fluid" src="./asset/images/ref/ptt_chem.png" alt="ptt_chem" />
                                    <img className="px-2 px-md-4 img-fluid" src="./asset/images/ref/true.png" alt="true" />
                                    <img className="px-2 px-md-4 img-fluid" src="./asset/images/ref/Thai_air.png" alt="Thai_air" />
                                    <img className="px-2 px-md-4 img-fluid" src="./asset/images/ref/กฟผ.png" alt="กฟผ" />
                                    <img className="px-2 px-md-4 img-fluid" src="./asset/images/ref/AOT.png" alt="AOT" />
                                    <img className="px-2 px-md-4 img-fluid" src="./asset/images/ref/dusit.png" alt="dusit" />
                                    <img className="px-2 px-md-4 img-fluid" src="./asset/images/ref/phyathai.png" alt="phyathai" />
                                </Swiper>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section>
                    <Container>
                        <hr />
                    </Container>
                </section>

                <section id="news-events">
                    <div className="bg-white-green py-5 position-relative">
                        <Container className="position-relative py-5 index-2">
                            <Row>
                                <Col xs="12" className="text-center mb-5">
                                    <img className="img-fluid pr-2" src="./asset/images/home/HL-06.png" width="400px" alt="HL-06" />
                                </Col>
                                {this.state.itemsNews.sort((item1, item2) => item1.id < item2.id ? 1 : -1).map((items, index) => {
                                    if (index > 3) return ('')
                                    return (
                                        <div className={"mb-4 mb-sm-2 " + ((this.state.news_events.top - ((this.state.height / 2) + (this.state.height / 3))) <= 0 ? "animated fadeIn" : "animated fadeOut")} key={`items-${index}`}>
                                            <div className="d-flex flex-wrap diamond-full bg-white border-green py-3">
                                                <Col xs="12" sm="5" md="4" lg="3" className="d-27">
                                                    <img className="img-fluid rounded" src={items.image} title={items.title} alt={items.title} />
                                                </Col>
                                                <Col xs="12" sm="7" md="8" className="d-27">
                                                    <h4 className="pt-3 pt-sm-0">{items.title}</h4>
                                                    <p className="py-3">{items.detailmore}</p>
                                                    <Link to={{ pathname: "/news", search: "?id=" + items.id }}><Button className="px-4 py-2 btn-blue">{t('more-btn')}</Button></Link>
                                                </Col>
                                            </div>
                                        </div>
                                    )
                                })}
                            </Row>
                            <Row>
                                <Col xs="12" className="text-center mt-5">
                                    <Link to="/news"><Button className="px-4 py-3 btn-blue">{t('more-btn')}<i className="fas fa-angle-right pl-2"></i></Button></Link>
                                </Col>
                            </Row>
                        </Container>
                        <div className="pos-absolute w-100 border-white bg-green diamond point-border-green"></div>
                    </div>
                </section>
            </div>
        )
    }
}

export default translate()(Home)
