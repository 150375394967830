import React, { Component } from 'react'
import Menu from '../component/menu'
import Contact from '../component/contact'

export default class PageContact extends Component {
    render() {
        return (
            <div>
                <Menu App={this.props.App}/>
                <Contact />
            </div>
        )
    }
}